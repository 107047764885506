/* eslint-disable no-undef */
import { chooseLocation } from './lib/chooseLocation'
import { openLocation } from './lib/openLocation'

const install = function (Vue, opts = {}) {
  //地图配置
  console.log('install',opts)
  if (opts.mapKey) {
    SmartMap.mapKey = opts.mapKey;
  }
  if (opts.mapType) {
    SmartMap.mapType = opts.mapType;
  }
  if (opts.height) {
    SmartMap.height = opts.height;
  }
  if (opts.width) {
    SmartMap.width = opts.width;
  }

};

const SmartMap = {
  mapType: "amap", //地图类型
  mapKey: "",
  width: "1000px",
  height: "500px",
  install,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  chooseLocation,
  openLocation,
};


export default SmartMap;
