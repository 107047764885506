<template>
  <iframe id="mapPage" width="100%" height="100%" frameborder="0" :src="iframeUrl"/>
</template>

<script>
export default {
  name: "so-location-picker",
  props: {
    title: String,
    options:Object,
    mapKey: {
      type: String,
      default: "",
    },
  },
  watch: {
    options:{
      deep:true,
      handler(){
        console.log('options..',JSON.stringify(this.options),this.options)
        this.getUrl()
      }
    }
  },
  data() {
    return {
      width: 500,
      visible: false,
      location: null,
      iframeUrl:''
    };
  },
  created() {
    this.getUrl()
    window.addEventListener("message", this.handleMessage, false);
  },
  destroyed() {
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    getUrl(){
      const { name,address,lat,lng } = this.options || {}
      console.log('lat,lng',lat,lng)
      this.iframeUrl = `https://smartopen.xddkj.cn/smart-map-components/index.html?key=${this.mapKey}&t=${new Date().getTime()}#/LocationPoint` + 
        (lat && lng ? `?lat=${lat}&lng=${lng}&name=${name||''}&address=${address||''}` : '')
    },
    handleMessage(event) {
      var loc = event.data;
      if(!loc) return
      if(loc.module == "pointPicker"){
        console.log('pointPicker',loc)
        this.location = loc;
        this.locationPicker();
      }
    },
    locationPicker() {
      this.$emit("locationPicker", this.location);
    },
  },
};
</script>

<style></style>