<template>
  <el-dialog top="8vh" 
    center
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
      append-to-body
  >
  <div :style="{height}">
    <so-location-picker :mapKey="mapKey" :mapType="mapType" :options="options"
      @locationPicker="onLocationPicker"/>
  </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="info"
        plain
        @click="handleClose"
        >取消</el-button
      >
      <el-button
        type="primary"
        :loading="loading"
        :disabled="!location"
        @click="onConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import LocationPicker from '../LocationPicker'
// import { Dialog,Button } from "element-ui";
export default {
  components:{
    // [Dialog.name]:Dialog,
    // [Button.name]:Button,
    [LocationPicker.name]:LocationPicker
  },
  name: "so-location-picker-viewer",
  props: {
    title: {
      type:String,
      default:'选择地址'
    },
    mapKey: {
      type: String,
      default: "",
    },
    mapType: String,
    width: {
      type: [String, Number],
      default: "1000px",
    },
    height: {
      type: [String, Number],
      default: "500px",
    },
  },
  data() {
    return {
      dialogVisible:false,
      location:null,
      options:null,
      loading:false,
    };
  },
  methods: {
    open(location) {
      this.options = Object.assign({},this.options,location)
      console.log('location',location)
      this.dialogVisible = true
    },
    onLocationPicker(data){
      console.log(data,'---data')
      this.location = data
    },
    //根据经纬度获取位置
    onConfirm(){
      if(!this.location){
        return this.$message.warning({message:'请选择地址'})
      }
      console.log('res',this.location)
      this.$emit('picker',this.location)
      this.dialogVisible = false
    },
    handleClose(){
      this.dialogVisible = false
      this.$emit('close')
    }
  },
};
</script>

<style>
</style>