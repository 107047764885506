/* eslint-disable no-undef */
import { openFile } from './lib/openFile'
import { openMedia } from './lib/openMedia'
import { previewImage } from './lib/previewImage'

const install = function (Vue, opts = {}) {
};

const SmartFile = {
  width: "1000px",
  height: "500px",
  install,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  openFile,
  openMedia,
  previewImage,
};


export default SmartFile;
