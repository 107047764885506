<template>
  <el-dialog top="16vh" 
    center
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
     append-to-body
     destroy-on-close
     custom-class="video-dialog"
  >
    <video v-if="src" :controls="controls" :autoplay="autoplay">
      <source :src="src" type="video/mp4">
    </video>
  </el-dialog>
</template>

<script>
export default {
  props:{
    controls:{
      type:Boolean,
      default:true,
    },
    autoplay:{
      type:Boolean,
      default:true,
    },
    onClose:Function
  },
  data() {
    return {
      dialogVisible:false,
      title:'',
      width: '1000px',
      src:''
    };
  },
  methods: {
    open(options = {}){
      const { name,src,width,height } = options
      if(!src) return 
      this.title = name || '视频预览'
      this.src = src
      this.dialogVisible = true
    },
    handleClose(){
      this.dialogVisible = false
      this.src = ''
      this.onClose && this.onClose()
    }
  },
};
</script>

<style lang="scss">
.video-dialog{
  .el-dialog__body{
    padding: 0;
    height: 563px;
  }
  video{
    width:100%;
    height:100%;
  }
}
</style>