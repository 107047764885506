import Vue from "vue";
import VideoViewer from "../../VideoViewer/index.vue";

const VideoViewerClass = Vue.extend(VideoViewer);
var instance = null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function openMedia(options) {
  return new Promise((r) => {
    if (!instance) {
      instance = new VideoViewerClass({
        propsData: {
          ...options,
          onClose:()=> close()
        },
      });
      instance.$mount();
      document.body.appendChild(instance.$el);
    }
    instance.open(options)
    const close = () => {
      instance.$el.remove();
      instance = null;
      r();
    };
  });
}