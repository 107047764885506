<template>
  <el-dialog
    top="8vh"
    center
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="close"
    append-to-body
    destroy-on-close
    custom-class="map-dialog"
  >
    <div :style="{ height }">
      <iframe
        id="mapPage"
        width="100%"
        height="100%"
        frameborder="0"
        :src="locationHref"
      >
      </iframe>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button
        style="width: 200px"
        size="large"
        type="primary"
        :loading="loading"
        @click="onConfirm"
        >确 定</el-button
      >
    </span> -->
  </el-dialog>
</template>

<script>
// import { Dialog } from "element-ui";

export default {
  // components: {
  //   [Dialog.name]: Dialog,
  // },
  props: {
    // mapKey: {
    //   type: String,
    //   default: "",
    // },
    mapType: String,
    win: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: "800px",
    },
    height: {
      type: [String, Number],
      default: "500px",
    },
  },
  data() {
    return {
      dialogVisible: false,
      title: "",
      location: null,
    };
  },
  computed: {
    locationHref() {
      if (!this.location) return;
      const { name, address, lat, lng } = this.location;
      const query = { name, address, lat, lng };
      const b = Object.keys(query)
        .filter((k) => !!query[k])
        .reduce((a, b) => {
          if (query[b]) {
            a.push(`${b}=${query[b]}`)
          }
          return a;
        }, []).join('&');
      return (
        `https://smartopen.xddkj.cn/smart-map-components/index.html?t=${new Date().getTime()}#/Address?${b}`
      );
    },
  },
  methods: {
    open(location) {
      const { name, address, lat, lng } = location;
      if (!((name || address) && lat && lng))
        return this.$message.warning({ message: "地址不能进行定位" });
      this.title = location.name || location.address;
      this.location = location;

      if (this.win) {
        //open打开
        window.open(this.locationHref);
        return;
      }

      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.map-dialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>
