import SmartMap from "./SmartMap";
import SmartFile from "./SmartFile";


const smart = {
  chooseLocation: SmartMap.chooseLocation,
  openLocation: SmartMap.openLocation,
  previewImage: SmartFile.previewImage,
  openFile: SmartFile.openFile,
  openMedia: SmartFile.openMedia,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const install = function (Vue, opts = {}) {
  console.log('opts',opts)
  Vue.use(SmartMap,opts)

  Vue.prototype.$smart = smart
};

/* istanbul ignore if */
// if (typeof window !== "undefined" && window.Vue) {
//   install(window.Vue,window.smartmap);
// }

export default {
  version: '0.0.1',
  install,
  ...smart
}