import Vue from "vue";
import MapViewer from "../../MapViewer/index.vue";
const MapViewerClass = Vue.extend(MapViewer);

import SmartMap from "../index";
var instance = null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function openLocation({
  lng,
  lat,
  name,
  address,
  win,
  width,
  height,
  success,
  fail,
}) {
  return new Promise((r, j) => {
    if (!instance) {
      instance = new MapViewerClass({
        propsData: {
          width,
          height,
          win,
          mapKey: SmartMap.mapKey,
          mapType: SmartMap.mapType,
        },
      });
      instance.$mount();
      document.body.appendChild(instance.$el);
    }
    instance.open({ lng, lat, name, address });

    const close = () => {
      instance.$off("close");
      instance.$el.remove();
      instance = null;
      console.log("close");
    };
    instance.$once("close", (res) => {
      console.log("close...");
      success && success(res);
      j(res);
      close();
    });
  });
}