import Vue from "vue";
import LocationPicker from "../../LocationPickerViewer/index.vue";
const LocationPickerClass = Vue.extend(LocationPicker);

import SmartMap from "../index";

var instance = null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function chooseLocation({
  lng,
  lat,
  name,
  address,
  success,
  fail,
  width,
  height,
  title,
}) {
  return new Promise((r, j) => {
    if (!instance) {
      instance = new LocationPickerClass({
        propsData: {
          width,
          height,
          title,
          mapKey: SmartMap.mapKey,
          mapType: SmartMap.mapType,
        },
      });
      instance.$mount();
      document.body.appendChild(instance.$el);
    }
    instance.open({
      lng,
      lat,
      name,
      address,
    });
    const close = () => {
      instance.$off("picker");
      instance.$off("close");
      instance.$el.remove();
      console.log("close");
      instance = null;
    };
    instance.$once("picker", (res) => {
      console.log("res", res);
      success && success(res);
      r(res);
      close();
    });
    instance.$once("close", (res) => {
      console.log("close", res);
      fail && fail(res);
      j(res);
      close();
    });
  });
}
