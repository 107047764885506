import Vue from "vue";
import ImageViewer from "element-ui/packages/image/src/image-viewer";

const ImageViewerClass = Vue.extend(ImageViewer);
var instance = null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function previewImage({ urls, i = 0, zIndex = 9999 }) {
  return new Promise((r) => {
    if (!instance) {
      instance = new ImageViewerClass({
        propsData: {
          zIndex,
          urlList: urls,
          initialIndex: i,
          onClose: () => {
            console.log('onclose')
            close();
          },
        },
      });
      instance.$mount();
      document.body.appendChild(instance.$el);
    }
    const close = () => {
      instance.$el.remove();
      instance = null;
      r();
    };
  });
}